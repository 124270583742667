import React, {useEffect, useContext} from "react";
import UiContext from '../context/ui';

function Loader({data}) {

    const { loaderMessage } = useContext(UiContext);
    
    useEffect(() =>{
        // console.log("loaderMessage:", loaderMessage);
      }, [loaderMessage])

      
    const isVisible = ()=> {
        return loaderMessage && loaderMessage.visibility;
    }

return (<div className="popup-screen-group">
    <div className={"popup-screen " + isVisible()}>
        <div className="popup-box">
            <div>
            <p className="popup-title">{"Transacting"}</p>
            </div>
            <div>
             
            </div>
        </div>
    </div>
</div>)
}


export default Loader;