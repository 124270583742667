import React, { useContext, useEffect, useState } from "react";
import Contract from "../contracts/CartoonKevins.json";
import UserContext  from '../context/user';
import UiContext  from '../context/ui';
import {CONTRACT_ADDRESS} from './Helper'

function MintButton () {

    const { connectedWalletAddress, web3Context } = useContext(UserContext);
    const { displayPopupWithCallback, displayLoaderWithCallback, loaderFinished } = useContext(UiContext);
    const [totalSupply, setTotalSupply] = useState(0);
    const [maxSupply, setMaxSupply] = useState(0);
    const [saleState, setSaleState] = useState(0);
    const [contractState, setContractState] = useState(null);
    const [numberToMint, setNumberToMint] = useState(1);
    const [isSoldOut, setIsSoldOut] = useState(false);

    useEffect( ()=>{

        const fetchWeb3 = async ()=> {
            if (web3Context) {
                await web3Context.eth.net.getId();    
                let contract_Address = CONTRACT_ADDRESS;
                if (contract_Address) {
                    const contractResult = new web3Context.eth.Contract(
                        Contract, contract_Address
                    );
                    setContractState(contractResult)
                    queryContract(contractResult);
                } else {
                    displayPopupWithCallback(()=>{  
                    },
                    "Error Connecting to the Etherum Network",
                    "Please connect to Mainnet", true);                    
                }
            }
        }

        fetchWeb3();
       
    },[web3Context, displayPopupWithCallback]);


    const queryContract = async (contractResult)=> {

        let totalSupplyResult = await contractResult.methods.totalSupply().call();
        setTotalSupply(totalSupplyResult);        
        let saleStateResult = await contractResult.methods.paused().call();
        setSaleState(saleStateResult);    
        let maxSupplyResult = await contractResult.methods.maxSupply().call();
        setMaxSupply(maxSupplyResult);    

        if (totalSupplyResult >= maxSupplyResult) {
            setIsSoldOut(true);
        }

    }

    const sendMintTransaction = async (contract)=>{        
        let getPrice = await contract.methods.cost().call();
        await contract.methods.mint(numberToMint).send({from:connectedWalletAddress, value:getPrice*numberToMint});
        loaderFinished();
        displayPopupWithCallback(()=>{
            console.log("randomize pressed");
        },
        "Mint Complete!",
        "You successfully minted Cartoon Kevin!", true);     
    }

    const displayTotalSupply = ()=>{        
        return (<div className="total-supply">{totalSupply}/{maxSupply}</div>)
    }

    return(<>
                             
            {
                isSoldOut ? <>
                 <div className="mint-button noselect" onClick={async ()=>{ 
                 }}>Sold Out</div>
                </> : <>
                <div className="mint-number">
                <div className="mint-button toggle noselect" onClick={async ()=>{ 
                    setNumberToMint(Math.max(1, numberToMint-1));
                }}>-</div>
                <div className="mint-button-number">
                {
                    numberToMint
                }
                </div>


            <div className="mint-button toggle noselect" onClick={async ()=>{ 
                    setNumberToMint(Math.min(5, numberToMint+1));
                }}>+</div>                 
                </div>
            
                 <div className="mint-button" onClick={async ()=>{ 

                if (saleState) {
                    displayPopupWithCallback(()=>{
                    },
                    "Error Minting",
                    "Sales are turned off", true);         
                    return;
                }
                
                displayLoaderWithCallback(async ()=>{
                    console.log("Finished loader")
                })                                
    
                if (web3Context === null) {
                    loaderFinished();
                    displayPopupWithCallback(()=>{
                        console.log("randomize pressed");
                    },
                    "Error Minting",
                    "Please connect your wallet.  Tip: Refresh the page after logging in to your wallet.", true);                    
                    return;
                 }
            
            try {
                
                if (connectedWalletAddress && contractState) {
                    await sendMintTransaction(contractState);
                } else {
                    displayPopupWithCallback(()=>{  
                    },
                    "Error Minting",
                    "Please connect a wallet to mint", true);                    
                }
            } catch (e) {
                if (e.message) {
                    loaderFinished();                                
                    displayPopupWithCallback(()=>{
                        console.log("randomize pressed");
                    },
                    "Error",
                    e.message, true);                    
                    }
                }
            }
        }>
          Mint
        </div>
                </>
        }            
        {
            saleState  === true ? <>mint paused <br/></> :<></>
        }
        {
            // saleState  == 1 ? <p>presale mint</p> :<></>
        }

        {
            displayTotalSupply()
        }
    </>)
}

export default MintButton;