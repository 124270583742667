import React, {useEffect, useContext} from "react";
import UiContext from '../context/ui';

function Popup({data}) {

    const { popupMessage, popupOkPressed, popupCancelPressed } = useContext(UiContext);
    
    useEffect(() =>{
        // console.log("alertMessage:", alertMessage);
      }, [popupMessage])

      
    const isVisible = ()=> {
        return popupMessage.visibility;
    }

return (<div className="popup-screen-group">
    <div className={"popup-screen " + isVisible()}>
        <div className="popup-box">
            <div>
            <p className="popup-title">{popupMessage ? popupMessage.title : ""}</p>
            <p className="popup-message">{popupMessage ? popupMessage.message : ""}</p>
            </div>
            <div>
                <div className="popup-button" onClick={()=>{
                    popupOkPressed();
                    console.log("Ok pressed")}}>
                    Ok
                </div>
                {
                    popupMessage && popupMessage.okOnly ? <></> : <div className="popup-button" onClick={()=>{
                        popupCancelPressed();
                        console.log("Cancel pressed")}}>
                        Cancel
                    </div>
                }
                
            </div>
        </div>
    </div>
</div>)
}


export default Popup;