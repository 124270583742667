import React, { useEffect, useState, useContext, useRef } from "react";
import MenuBar from "./MenuBar";
import MintButton from "./MintButton";
import UserContext  from '../context/user';
import bg from './bg.jpg';
import {ETHERSCAN_CONTRACT_ADDRESS, OPENSEA_ADDRESS} from './Helper';

function HomePage() {

  const { isConnected, connectToWeb3 } = useContext(UserContext);
//   const intervalRef = useRef();
  const currentAvatarsShown = useRef([]);
  const [updateAvatarsShown, setUpdateAvatarsShown] = useState(false);

  const getAvatars = () => {

    let avatarsList = ["/images/kevins/1.jpg",
        "/images/kevins/2.jpg",
        "/images/kevins/3.jpg",
        "/images/kevins/4.jpg",
        "/images/kevins/5.jpg",
        "/images/kevins/6.jpg",
        "/images/kevins/7.jpg",
        "/images/kevins/9.jpg",
        "/images/kevins/10.jpg",
        "/images/kevins/11.jpg",
        "/images/kevins/12.jpg",
        "/images/kevins/13.jpg",
        "/images/kevins/14.jpg",
        "/images/kevins/15.jpg"
        ];

  if (currentAvatarsShown.current.length === 0) {
    
    let updatedList = avatarsList.slice(0,5);
    currentAvatarsShown.current = updatedList;
  } else {

    let current = [...currentAvatarsShown.current];
    const reRollRandom = (a)=>{
      let randomIndex = Math.floor(Math.random()*a.length);
      return randomIndex;
    }        
    let avatarIndexRolled = reRollRandom(avatarsList);
    let avatarFound = false;
    while (!avatarFound) {        
      let dupeFound = false;          
      for (let i = 0; i < current.length; i++) {
        let currentStringCheck = current[i];
        if (currentStringCheck === avatarsList[avatarIndexRolled]) {
          dupeFound = true;
        }
      }
      if (dupeFound) {
        avatarIndexRolled = reRollRandom(avatarsList);
      }else {
        avatarFound = true;
      }
    }        
    
    current[reRollRandom(current)] = avatarsList[avatarIndexRolled];    

    currentAvatarsShown.current = [...current];

    setUpdateAvatarsShown(!updateAvatarsShown);
  }
}

  useEffect(() => {

    //   if (intervalRef.current == null) {
    //     intervalRef.current = setInterval(getAvatars, 1000);
    // }
    
    getAvatars();
    getAvatars();
    
    // Clear the interval when this hook/component unmounts so it doesn't keep
    // running when this component is gone.
    // return () => {
        // if (intervalRef.current != null) {
            // clearInterval(intervalRef.current);
        // }      
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const displayAvatarsRow = ()=> {
    return (currentAvatarsShown.current.map((avatarLink, index) =>{
      return  <img alt="" key={index} className="web-avatar-square" src={avatarLink}></img> 
    }))
  }

  const footer = ()=>{
      return(<div className="footer">

<img alt="" className="top-bar-logo" src="/images/Cartoon Kevins.png"/>
        
      <div className="top-bar-menu-item">
      <a target="_blank" rel="noreferrer" href={OPENSEA_ADDRESS}>
      <img alt="" className="top-bar-icon" src="images/opensea.png"/>            
      </a>
      </div>
      <div className="top-bar-menu-item">
      <a target="_blank" rel="noreferrer" href={ETHERSCAN_CONTRACT_ADDRESS}>
      <img alt="" className="top-bar-icon" src="images/logo-etherscan-white.svg"/>            
      </a>
      </div>
      <div className="top-bar-menu-item">
      <a target="_blank" rel="noreferrer" href="https://twitter.com/cartoonkevins">
      <img alt="" className="top-bar-icon" src="images/twitter.png"/>            
      </a>
      </div>
      <div className="top-bar-menu-item">
      <a target="_blank" rel="noreferrer" href="https://instagram.com/cartoonkevins">
      <img alt="" className="top-bar-icon" src="images/instagram.png"/>            
      </a>
      </div>
      </div>)
  }
  return (<div className="App-Contents">
    <div>
      <div className="top-bar desktop-hide">                   
        <img alt="" className="top-bar-logo" src="/images/Cartoon Kevins.png"/>
      </div>
      <MenuBar />
      
      <div className="bg" style={{backgroundImage:`url(${bg})`}} >
            
     <div className="web-block">                  
        <div className="web-block-center">
        <img alt="" className="web-avatar" src="/images/Kevin-transparent.png"></img>
        <div className="web-block-1-3">
        <p style={{marginTop:0}}><b>3,333 Cartoon Kevins</b></p>
        <p><b>3 Legendary Kevins</b></p>
        <p><b>33 Robot & 33 Rainbow Kevins</b></p>
        </div>
        </div>
      </div>
    </div>

     <div className="web-block">          
      <div className="web-block-mint">        
       {isConnected ?  <MintButton /> :  <div className="mint-button" onClick={()=>{connectToWeb3();}}>Connect</div> }
      </div>
    </div>
    <div className="web-block ourtool">          
         <div className="web-block-center">Connect your wallet to the Ethereum Mainnet to view mint status. <br/>1 Cartoon Kevin is 0.0099 ether to mint + gas fees.<br/>Max of 5 mints per transaction.</div>
     </div>

  <br/>
    <div className="web-block avatars">          
      <div className="web-block-center-avatars">
        <br/>
        <p className="web-block-text-large dark">Get your own Cartoon Kevin!</p>

      <div className="avatar-scroll">
        {
          displayAvatarsRow()
        }
      </div>

    </div>        
    </div>
     
     
        <div className="web-block who">          
            <div className="web-block-center">
                <p className="web-block-text-large dark"><b>Who is Cartoon Kevin?</b></p>
                <p>A legend was born out of chaos. Kevin represents a hero that kept his composure and arose when time needed him most.</p>
                <p>No roadmap, No discord, only Kevin vibes for now.</p>
                <p>Kevin is a newly found symbol for some in the NFT community, representing true grit to achieve the impossible.</p>
                <p>Everyone can be a Kevin.</p>
            </div>


            <div className="web-block-center">
                <p className="web-block-text-large dark"><b>What am I minting?</b></p>
                <p>By minting a Cartoon Kevin you're supporting a builder in the NFT space to build more for Cartoon Kevin holders.</p>
                <p>You are not buying a security that holds a monetary value, the value of the NFT is decided in the secondary market.</p>
            </div>

            <div className="web-block-center">
                <p className="web-block-text-large dark"><b>When is reveal?</b></p>
                <p>Reveal will be when the collection sells out. Selling out can take several days to as long as a month from launch.</p>
            </div>

            <div className="web-block-center">
                <p className="web-block-text-large dark"><b>Team</b></p>
                
                <img alt="unicornmoneyeth" src="images/unicornmoney.png"></img>
    
                <div className="top-bar-menu-item">
                <a target="_blank" rel="noreferrer" href="https://twitter.com/unicornmoneyeth">
                  <img alt="" className="top-bar-icon" src="images/twitter.png"/>      
                </a>
                <p>unicornmoney</p>      
                </div>
                <p>CEO, Web3 Developer, Game Developer, NFT Collector, Architect of Things</p>
                <p>Past projects include: Steven Universe: Save the Light, Teeny Titans (Mobile), and more.</p>
            </div>


            

            {
            footer()
        }

        </div>        

     <div>
    </div>
    </div>
  </div>)
  
}

export default HomePage;