import React, { useState, useEffect, useContext, useCallback } from "react";
import {detectWeb3} from "../getWeb3";
import getWeb3 from "../getWeb3";
import {API}  from 'aws-amplify';
// import aws_config from '../aws-exports';
// import { useRouter } from 'next/router'
import UiContext  from '../context/ui';
import CartoonKevins from "../contracts/CartoonKevins.json";
import { useNavigate } from "react-router-dom";

// Amplify.configure(aws_config);

const UserContext = React.createContext({});

const UserProvider = ({ children }) => {
  const [signedIn, setSignedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [signature, setSignature] = useState("");
  const [nonce, setNonce] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [connectedWalletAddress, setConnectedWalletAddress] = useState("");
  const [accounts, setAccounts] = useState(null);
  const {sendAlertMessage, clearMessages, displayPopupWithCallback } = useContext(UiContext);
  const [currentPage, setCurrentPage] = useState("My Collection");
  const [currentTokenSelected, setCurrentTokenSelected] = useState(-1);
  const [currentTokenProperties, setCurrentTokenProperties] = useState(null);
  const [currentTokenSelectedCreated, setCurrentTokenSelectedCreated] = useState(false);
  const [web3Context, setWeb3Context] = useState(null);
  const navigate = useNavigate();

  const defaultAttributeValues = {
    "Background" : 0,
    "Body" : 0,
    "Eyes" : 0,
    "Hair" : 0,
    "Horn" : 0,
    "Mouth" : 0,
    "Outfit" : 0
};

  const [currentAttributesSelected, setCurrentAttributesSelected] = useState(defaultAttributeValues);

  const resetAttributes = ()=>{
    setCurrentAttributesSelected(defaultAttributeValues);
  }

  const resetUser = useCallback((redirect) => {
    setIsConnected(false);
    setSignedIn(false);
    setUser(null);
    setSignature("");
    setNonce("");
    if (redirect) {
      navigate("/")
    }
  },[navigate]);

  
  const connectWallet = async ()=> {     
    
    try {
      
      if (web3Context == null) {
        await detectWeb3Now();
      }

      if (web3Context == null) {
        const web3 = await getWeb3();
        setWeb3Context(web3);
        const accounts = await web3.eth.getAccounts();
        setAccounts(accounts);      
      } else {

        if (web3Context && web3Context.eth) {

          const accounts = await web3Context.eth.getAccounts();
         setAccounts(accounts);     
          
        } else {
      
          displayPopupWithCallback(()=>{  
          },
          "Error",
          "No accounts found", true);
        }
        
      }      
    }catch (e) {
      if (e.message) {
        sendAlertMessage(e.message);
      }
    }    
  }


  const detectWeb3Now = async ()=>{

    if (!window.ethereum) {      
      displayPopupWithCallback(()=>{  
      },
      "Error",
      "Please use a compatible browser", true);
    }

    const web3DetectedResult = await detectWeb3();
    const web3 = await getWeb3();
    setWeb3Context(web3);

    if (web3DetectedResult) {
      if (web3DetectedResult[0]) {
        setIsConnected(true);
        setConnectedWalletAddress(web3DetectedResult[0]);
        sendAlertMessage('');
      }
    }else {
      sendAlertMessage('Please Connect a Wallet First');
    }
  }

  const queryConnectedWalletAddress = ()=>{    
    if (connectedWalletAddress) {
      clearMessages();
      queryForAddress(connectedWalletAddress);
    }else {
      sendAlertMessage('Please Connect a Wallet First');
    }
    
  }

  useEffect(()=>{
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', async (accounts) => {
        if (accounts && accounts.length === 0) {
          resetUser(true);        
        }else if (accounts) {
          if (accounts.length > 0)  {
            if (connectedWalletAddress !== accounts[0]) {
              setSignedIn(false);
              setIsConnected(true);
              setUser(accounts[0]);
              setConnectedWalletAddress(accounts[0]);  
            }else {
              setIsConnected(true);
              setUser(accounts[0]);
              setConnectedWalletAddress(accounts[0]);  
            }         
          }
        }else {
          resetUser(true);
        }
      });
    }
   
  },[connectedWalletAddress, resetUser]);

  const connectToWeb3 = async ()=> {        
      await detectWeb3Now();
  }

  const adminCheck = async ()=> {
    let body = {signature:signature, nonce:nonce, address:connectedWalletAddress};
    let result = await API.post("cartoonkevinsApi","api/admincheck", {body:body}).then(async response => {                                    
      if (response.ErrorResponse) {
        console.log("response.ErrorResponse:", response.ErrorResponse); 
      }else {
      }
      return response;
    });    
    return result;
  }

  const uploadImage = async (imageData, attributeType, attributeName, id )=>{    

    console.log("Upload:", imageData, attributeType, attributeName, "ID:", id);

    if (imageData && attributeType) {
      
      let body = {signature:signature, nonce:nonce, address:connectedWalletAddress, imageData:imageData, attributeType:attributeType, attributeName:attributeName, id:id};      
      console.log("Body:", body);
      
      let result = await API.post("cartoonkevinsApi","api/uploadImage", {body:body}).then(async response => {                                    
        if (response.ErrorResponse) {
          console.log("response.ErrorResponse:", response.ErrorResponse); 
        } else {
        }
        return response;
      });    
      return result;
    }
  }


  const createNFT = async (imagesArray)=>{      
    if (currentTokenSelected && currentAttributesSelected) {
      let body = {signature:signature, nonce:nonce, attributes:currentAttributesSelected, address:connectedWalletAddress, token:currentTokenSelected, imagesArray:imagesArray};
      let result = await API.post("unicorndoodsApi","api/createNFT", {body:body}).then(async response => {                                    
        if (response.ErrorResponse) {
          console.log("response.ErrorResponse:", response.ErrorResponse); 
        }else {
        }
        return response;
      });    
      return result;
    }
  }

  const queryForTokens = async (ids)=> {
    let result = await API.get("unicorndoodsApi","api/tokens?ids=" + ids).then(async response => {                                
      if (response.ErrorResponse) {
        console.log("response.ErrorResponse:", response.ErrorResponse); 
      }else {
      }
      return response;
    });    
    return result;
  }


  const queryForToken = async (id)=>{
    let result = await API.get("unicorndoodsApi","api/token?id="+id).then(async response => {                                
      if (response.ErrorResponse) {
        console.log("response.ErrorResponse:", response.ErrorResponse); 
      }else {
      }
      return response;
    });    
    return result;
  }

  const queryForAddress = async (walletAddress)=>{
    await API.get("unicorndoodsApi","api/address?publicAddress=" + walletAddress.toLowerCase()).then(async response => {
      if (response.ErrorResponse) {
        console.log("response.ErrorResponse:", response.ErrorResponse); 
      }else {
        const web3 = await getWeb3();
        await window.ethereum.enable();
        const currentNonce = response.data.Item.nonce;
        let resultSigned = await handleSignMessage(web3, connectedWalletAddress, currentNonce);        
        let verifyResult = await API.get("unicorndoodsApi","api/verifySignature?publicAddress=" + connectedWalletAddress.toLowerCase() + '&nonce=' + currentNonce + '&signature=' + resultSigned.signature);                      
        if (verifyResult) {
          if (verifyResult.success){
            setSignedIn(true);
            setSignature(resultSigned.signature);
            setNonce(currentNonce);
            clearMessages();
          }            
        }
      }
    }).catch(error =>{      
      if (error && error.response && error.response.data) {
        sendAlertMessage(error.response.data.error);
        resetUser(true);
      }
    })
  }

  const handleSignMessage = (web3, publicAddress, nonce) => {
    try {
      return new Promise((resolve, reject) =>        
       web3.eth.personal.sign(`Please sign this message to verify you are the owner of this wallet. Signature ID:${nonce}`,
        publicAddress, (err, signature) => {
        if (err){
            sendAlertMessage(err.message);
          return reject(err);}
        return resolve({ publicAddress, signature });
      }));
    }
    catch (e) {
      if (e.message) {
        sendAlertMessage(e.message);
      }
    }
  };

  
  const getCollection = async ()=>{
      if (web3Context) {
        let web3 = web3Context;
        const networkId = await web3.eth.net.getId();
        const deployedNetwork = CartoonKevins.networks[networkId];
        try {
            let contract_Address = deployedNetwork && deployedNetwork.address;
            const cartoonKevinsContract = new web3.eth.Contract(
              CartoonKevins.abi, contract_Address
            );                       
            let balanceOfUser =  await cartoonKevinsContract.methods.walletOfOwner(connectedWalletAddress).call();
            return balanceOfUser;
        }catch (e) {
          return [];
        }
      }
      return [];
  }


return (
    <UserContext.Provider
      value={{currentPage, currentAttributesSelected, accounts, connectToWeb3, uploadImage, adminCheck, currentTokenProperties, web3Context, signature, nonce, getCollection, resetAttributes, setCurrentTokenProperties, setCurrentTokenSelected, setCurrentAttributesSelected, currentTokenSelectedCreated, setCurrentTokenSelectedCreated, createNFT, setCurrentPage, queryForTokens, queryForToken, signedIn, user, isConnected, connectedWalletAddress, queryConnectedWalletAddress, connectWallet, setSignedIn, handleSignMessage}}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
export {UserProvider};