import './App.css';
import HomePage from './components/HomePage';
import Popup from './components/Popup';
import Loader from './components/Loader';


function App() {
  return (
    <div className="App">      
      <Loader/> 
      <Popup/> 
      <HomePage />      
    </div>
  );
}

export default App;
